import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  margin-left: 20px;
`;

const Arrow = () => (
  <Icon
    width="53"
    height="11"
    viewBox="0 0 53 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.8698 5.12452C52.8698 5.07333 52.8386 5.02731 52.791 5.00838L42.4299 0.883864C42.3951 0.869991 42.3558 0.872501 42.323 0.890701C42.2902 0.908902 42.2673 0.940869 42.2607 0.97778L42.1272 1.71682C42.1166 1.77536 42.1488 1.83325 42.204 1.85521L50.1195 4.99952H1C0.930964 4.99952 0.875 5.05548 0.875 5.12452V5.87552C0.875 5.94455 0.930964 6.00052 1 6.00052H50.0901L42.1918 9.14489C42.1365 9.16689 42.1044 9.22479 42.115 9.28331L42.2489 10.0223C42.2556 10.0592 42.2785 10.0911 42.3113 10.1093C42.3441 10.1275 42.3833 10.13 42.4181 10.1162L52.7909 5.99167C52.8385 5.97275 52.8698 5.92672 52.8698 5.87552V5.12452Z"
      fill="white"
      stroke="white"
      strokeWidth="0.25"
      strokeLinejoin="round"
    />
  </Icon>
);

export default Arrow;
