import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

// Components
import Arrow from '../icons/arrow';

const HeroArticle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;

  & h2,
  p {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h2,
  p,
  a {
    margin: 0;
  }

  & h2 {
    line-height: 160%;
  }

  & a:before {
    content: '';
    display: block;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(50, 50, 50, 0) 0%,
      rgba(35, 35, 35, 0.34) 100%
    );

    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    display: block;
    width: 100%;

    object-fit: cover;
    max-height: 600px;
  }

  &:first-of-type {
    & img {
      max-height: calc(100vh - 200px);
    }
  }

  & div {
    position: absolute;

    max-width: 700px;

    bottom: 0;
    left: 0;

    padding: 60px;
    @media (max-width: 1200px) {
      padding: 30px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }

    color: #fff;
  }

  & a {
    width: 100%;
    height: 100%;

    position: relative;
    display: block;

    color: #fff;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    font-size: 14px;
    letter-spacing: 0.05em;
  }

  & span {
    text-transform: uppercase;
  }
`;

const HeroJournalArticle = ({ data, link }) => (
  <HeroArticle>
    <Link to={`/journal/${link}`}>
      {data.image.fluid !== null && (
        <img
          src={data.image.fluid.srcWebp}
          srcSet={data.image.fluid.srcSetWebp}
          alt={data.image.alt}
          loading="lazy"
        />
      )}
      <div>
        <h2>{data.title.text}</h2>
        <p>{data.excerpt}</p>
        <span>
          Read More <Arrow />
        </span>
      </div>
    </Link>
  </HeroArticle>
);

export default HeroJournalArticle;
