import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';
import JournalCard from '../journal/journal-card';

const ProductTextContainer = styled.div`
  padding: 60px 0;
  text-align: center;

  & h2 {
    margin: 0;
    line-height: 100%;
  }

  & h2,
  & a {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & a {
    line-height: 100%;

    letter-spacing: 0.04em;

    color: rgba(35, 35, 35, 0.5);

    &:hover {
      color: #934e2a;
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 30px 0;
  }
`;

const ArticlesContainer = styled.div``;

const CollectionText = styled.div`
  max-width: 660px;
  margin: 0 auto;
`;

const JournalArticles = ({ data, articles }) => {
  const journalArticles = articles.map(article => (
    <Col col={12} md={4} key={article.articles.document.prismicId}>
      <JournalCard data={article.articles.document} />
    </Col>
  ));

  return (
    <Container>
      <Row justifyContent="center">
        <Col col={12} md={8}>
          <ProductTextContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: data.journal_title.html,
              }}
            />
            <CollectionText
              dangerouslySetInnerHTML={{
                __html: data.journal_text.html,
              }}
            />
            <Link to={`/journal`}>{data.link_text}</Link>
          </ProductTextContainer>
        </Col>

        <Col col={12}>
          <ArticlesContainer>
            <Row>{journalArticles}</Row>
          </ArticlesContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default JournalArticles;
