import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';

const ThreeTwo = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;

const Wrapper = styled.div`
  padding: 0;
`;

const Text = styled.div`
  text-align: center;

  h2 {
    margin: 15px 0 0 0;
    font-size: 18px;
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  & button {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.9px;
    color: #323232;
  }
`;

const LandscapeImages = ({ data }) => {
  const images = data.map((image, index) => {
    return (
      <Col col={6} key={index}>
        <ThreeTwo>
          <div>
            {image?.pageLink?.url !== undefined &&
            image?.pageLink?.url !== null ? (
              <a href={image?.pageLink?.url} target={image?.pageLink?.target}>
                {image.full_width_image.fluid !== null && (
                  <img
                    alt={image.full_width_image.alt}
                    srcSet={image.full_width_image.fluid.srcSetWebp}
                    src={image.full_width_image.fluid.srcWebp}
                    loading="lazy"
                  />
                )}
              </a>
            ) : (
              <>
                {image.full_width_image.fluid !== null && (
                  <img
                    alt={image.full_width_image.alt}
                    srcSet={image.full_width_image.fluid.srcSetWebp}
                    src={image.full_width_image.fluid.srcWebp}
                    loading="lazy"
                  />
                )}
              </>
            )}
          </div>
        </ThreeTwo>

        {image.call_to_action.text && (
          <Text>
            <h2>{image.call_to_action.text}</h2>
            <button>
              <a href={image?.pageLink?.url} target={image?.pageLink?.target}>
                {image.button_text}
              </a>
            </button>
          </Text>
        )}
      </Col>
    );
  });

  return (
    <Container>
      <Wrapper>
        <Row>{images}</Row>
      </Wrapper>
    </Container>
  );
};

export default LandscapeImages;
